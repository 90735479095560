<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        class="ma-0 mb-3 py-0"
      >
        <v-card class="pa-0">
          <v-breadcrumbs :items="breadcrumbItems">
            <template v-slot:divider>
              <v-icon color="primary">
                mdi-chevron-right
              </v-icon>
            </template>
          </v-breadcrumbs>
        </v-card>
      </v-col>
    </v-row>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="white--text primary">
              Add Screen
            </v-card-title>
            <v-row class="pa-4">
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="name"
                  outlined
                  label="Name*"
                  :rules="requiredRule"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="selectedMO"
                  outlined
                  :items="mos"
                  name="selectedMO"
                  label="Media Owner*"
                  :rules="requiredRule"
                  item-text="friendlyName"
                  :item-value="'friendlyName'"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="category"
                  outlined
                  label="Category"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="webLink"
                  outlined
                  label="Link To Format"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="filenameConventionPrefix"
                  outlined
                  label="File Name Convention"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="width"
                  outlined
                  label="Width*"
                  :rules="requiredRule"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="height"
                  outlined
                  label="Height*"
                  :rules="requiredRule"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="offsetX"
                  outlined
                  label="Offset X"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="offsetY"
                  outlined
                  label="Offset Y"
                />
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="selectedRotation"
                  outlined
                  label="Rotation"
                  :items="rotationTypes"
                  item-text="selectedRotation"
                />
              </v-col>
            
              <v-col cols="6">
                <v-select
                  v-model="selectedMotion"
                  outlined
                  label="Motion Support*"
                  :rules="requiredRule"
                  :items="motionTypes"
                  item-text="name"
                  :item-value="'name'"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="slotBreakSeconds"
                  outlined
                  label="Slot Break (Seconds)*"
                  :rules="requiredRule"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="slotDurationSeconds"
                  outlined
                  label="Slot Duration (Seconds)*"
                  :rules="requiredRule"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="maxFileSize"
                  outlined
                  label="Max file size (KB)"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="publishType"
                  outlined
                  label="Publish Type"
                  :items="publishTypes"
                  item-text="name"
                  :item-value="'name'"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="isResponsive"
                  outlined
                  label="Responsive"
                  :items="[true,false]"
                />
              </v-col>                
              <v-col cols="6">
                <v-select
                  v-model="machineType"
                  outlined
                  label="Machine Type"
                  :items="machineOptions"
                  item-text="name"
                  :item-value="'name'"
                  clearable
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="operatingSystem"
                  outlined
                  label="Operating System"
                  :items="operatingSystemOptions"
                  item-text="name"
                  clearable
                  :item-value="'name'"
                  
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="syncFrequencySeconds"
                  outlined
                  label="Sync Frequency (Seconds)"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="playerSoftware"
                  outlined
                  label="Player Software"
                  :items="playerSoftwares"
                  item-text="name"
                  :item-value="'name'"
                  clearable
                />
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="white--text primary"
                  @click="addScreen"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
  import ScreenController from '@/services/controllers/Screen'

  export default {
    data: () => ({
      breadcrumbItems: [
        {
          text: 'Screens',
          disabled: false,
          href: '/#/dashboard/formats',
        },
        {
          text: 'Add Screen',
          disabled: true,
        },
      ],
      headers: [
        {
          text: 'Media Owner',
          align: 'start',
          sortable: true,
          value: 'mediaOwner.friendlyName',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Category',
          align: 'start',
          sortable: true,
          value: 'category',
        },
        {
          text: 'Height',
          align: 'start',
          sortable: true,
          value: 'height',
        },
        {
          text: 'Width',
          align: 'start',
          sortable: true,
          value: 'width',
        },
        {
          text: 'Orientation',
          align: 'start',
          sortable: true,
          value: 'orientation',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
        },
      ],
      addedBrandingBadgeScreens: [],
      name: null,
      selectedMO: null,
      category: null,
      formats: [],
      offsetY: 0,
      offsetX: 0,
      height: null,
      width: null,
      selectedRotation: 0,
      selectedMotion: null,
      publishType: null,
      machineType: null,
      maxFileSize: 0,
      motionTypes: [],
      isResponsive:false,
      filenameConventionPrefix: null,
      rotationTypes: ['-90', '0', '90'],
      mos: [],
      orientations: ['portrait', 'landscape'],
      frequency: ['Network', 'Landmark'],
      publishTypes: [],
      playerSoftwares: [],
      machineOptions: [],
      webLink: null,
      operatingSystemOptions: [],
      valid: false,
      requiredRule: [(v) => !!v || 'Field is required'],
      slotDurationSeconds: null,
      slotBreakSeconds: null,
      operatingSystem: null,
      playerSoftware: null,
      screenAddError: null,
      syncFrequencySeconds: 0,
    }),
    created () {
      ScreenController.allScreens().then((res) => {
        this.formats = res.data
      })

      ScreenController.getMOList()
        .then((res) => {
          this.mos = res.data
        })
      ScreenController.getPublishTypes()
        .then((res) => {
          this.publishTypes = res.data
        })
      ScreenController.getMotionSupport()
        .then((res) => {
          this.motionTypes = res.data
        })
      ScreenController.getMachineTypes()
        .then((res) => {
          this.machineOptions = res.data
        })
      ScreenController.getOSTypes()
        .then((res) => {
          this.operatingSystemOptions = res.data
        })
      ScreenController.getPlayerSoftware()
        .then((res) => {
          this.playerSoftwares = res.data
        })
    },
    methods: {
      addScreen () {
        this.$refs.form.validate()
        if (this.valid === true) {
          ScreenController.addScreen({
            deliveryTypeId: 1,
            name: this.name,
            mediaOwner: this.selectedMO,
            category: this.category,
            offsetX: parseInt(this.offsetX),
            offsetY: parseInt(this.offsetY),
            width: parseInt(this.width),
            height: parseInt(this.height),
            rotation: parseInt(this.selectedRotation),
            slotDurationSeconds: parseInt(this.slotDurationSeconds),
            slotBreakSeconds: parseInt(this.slotBreakSeconds),
            maxFileSizeKB: parseInt(this.maxFileSize),
            motionSupport: this.selectedMotion,
            webLink: this.webLink,
            filenameConventionPrefix: this.filenameConventionPrefix,
            brandingBadgeScreenId:
              this.addedBrandingBadgeScreens.length > 0
                ? this.addedBrandingBadgeScreens[0].id
                : 0,
            dynamicScreen: {
              publishType: this.publishType,
              machineType: this.machineType,
              operatingSystem: this.operatingSystem,
              playerSoftware: this.playerSoftware,
              syncFrequencySeconds: this.syncFrequencySeconds,
              isResponsive: this.isResponsive,
            },
          })
            .then((res) => {
              this.$router.push({
                name: 'EditFormat',
                query: { id: res.data.id },
              })
              
              this.snackbarSuccess = true
              this.name = null
              this.selectedMO = null
              this.addedBrandingBadgeScreens = []
              this.webLink = null
              this.category = null
              this.offsetY = 0
              this.offsetX = 0
              this.height = null
              this.width = null
              this.selectedRotation = null
              this.selectedMotion = null
              this.maxFileSize = 0
              this.publishType = null
              this.machineType = null
              this.operatingSystem = null
              this.playerSoftware = null
              this.$root.$emit('snackbarSuccess', 'Screen created successfully.')
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', ''+err.response.data.message)
            })
        }
      },
    },
  }
</script>
